@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}
body{
    background: transparent;
}

.container{
    max-width: 1000px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid red; */
    background: transparent;
}

.header{
    text-align: center;
}

.nav{

    padding: 10px;
    overflow-x: scroll;
    
    white-space: nowrap;
}

.titlebox{
    font-size: 20px;
    margin: 2px;
    padding: 3px 6px;
    border: 1px solid #1b9bff;
    border-radius: 7px;
    display: inline;
    cursor:pointer;
}

.titlebox:hover{
    background: #1b9bff;
    transition: 0.7s;
}

.contentbody{
    background: transparent;
    /* border: 1px solid salmon; */
    padding: 0px 30px;
}

.selected{
    background: #1b9bff;
    /* transition: 0.7s; */
}

.unselected{
    background: transparent;
}

@media screen and (min-width : 768px) {
    .contentbody{
        padding: 0px 90px;
    }
}

