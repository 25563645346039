.App {
    text-align: center;
    padding: 20px;
  }
  
  input {
    padding: 10px;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding: 5px;
    cursor: pointer;
  }
  
  li:hover {
    background-color: #f0f0f0;
  }
  